import * as TYPES from '@/constants/actionTypes';

export function updateToken(token) {
  return {
    type: TYPES.UPDATE_TOKEN,
    payload: token,
  };
}

export function clearToken(value) {
  return {
    type: TYPES.CLEAR_TOKEN,
    payload: value
  };
}

export function updateUser(user = {}) {
  return {
    type: TYPES.UPDATE_USER,
    payload: user
  };
}

export function insertMedia(mediaId) {
  return {
    type: TYPES.INSERT_MEDIAID,
    payload: mediaId
  };
}