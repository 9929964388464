import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux' 
import { persistStore } from 'redux-persist'  
import { PersistGate } from 'redux-persist/lib/integration/react';
import { renderRoutes } from "react-router-config";
import Interceptors from '@@/Interceptors';
import { HashRouter as Router } from "react-router-dom";
import { store } from '@/store'
import routes from '@/router';

import './utils/rem';
import './styles/index.less';
// import './styles/style1024_1366/index.less';
// import './styles/style1024_1366/home.less';

ReactDOM.render(
  <Provider store={store}>   
    <PersistGate loading={null} persistor={persistStore(store)}>   
      <Router>
        <Interceptors />
        {renderRoutes(routes)}
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)