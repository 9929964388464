export const HOME_SAVE_NEWS_DATA = 'HOME_SAVE_NEWS_DATA';

export const HOME_SAVE_RESOURCE = 'HOME_SAVE_RESOURCE';

export const HOME_SAVE_REFEFERENCE = 'HOME_SAVE_REFEFERENCE';

export const HOME_SAVE_REFEFERENCELIST = 'HOME_SAVE_REFEFERENCELIST';

export const SET_NAV_JUMP_STATUS = 'SET_NAV_JUMP_STATUS';

export const SET_VIDEO_STATUS = 'SET_VIDEO_STATUS';

// login
export const UPDATE_TOKEN = 'UPDATE_TOKEN';

export const UPDATE_USER = 'UPDATE_USER';

export const CLEAR_TOKEN = 'CLEAR_TOKEN';

export const INSERT_MEDIAID = 'INSERT_MEDIAID';
