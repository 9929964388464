import axios from 'axios';
import { store } from '@/store';

// post
export function post (url, data) {
  const { login = {} } = store.getState();
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      // 添加公共的请求头
      headers: { token:  login?.token || '' },
      // data: qs.stringify(data),
      data,
    })
      .then(res => resolve(res.data))
      .catch(err => console.log(err))
  })
}

// get
export function get (url, params = '') {
  const { login = {} } = store.getState();

  return new Promise((resolve, reject) => {
    axios({
      url,
      method: 'GET',
      // 添加公共的请求头
      headers: { token: login?.token || '' },
      params,
    })
      .then(res => resolve(res.data))
      .catch(err => console.log(err))
  })
}