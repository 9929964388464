const prefix = '/api/jf/stu/';
const userPrefix = '/api/auth/user/';

// 首页
export const getNewsRecommendeList = '/api/admin/newsRecommend/getNewsRecommendeList';  // 重要新闻 + 新闻页面 轮播图 GET

export const getHomeBanner = '/api/admin/ad/editCarousel';  // 轮播图 GET

// export const getResourceWebsite = '/api/admin/resourceWebsite/getResourceWebsite';  // 数字资源 GET old

export const getResourceWebsiteListReturn = '/api/admin/resourceWebsite/getResourceWebsiteListReturn';  // 数字资源 GET

export const getIndexJfBooks = '/api/admin/jfBookEntity/getIndexJfBooks';  // 教辅展示 GET

// 智能教辅
export const getJfBookList = '/api/admin/jfBookEntity/getJfBookList';  // 教辅列表 POST

export const getJfBookTryLearn = '/api/jf/stu/webSite/tryLearn/yz/getJfBookTryLearn';  // 教辅详情 GET

// 数字资源
export const getResourceList = '/api/admin/resourceEntity/getResourceList';  // 数字资源列表 POST

export const getReferenceFile = '/api/admin/resourceWebsite/getReferenceFile';  // 数字资源列表 GET

// 新闻
export const getNewsList = '/api/admin/news/getNewsList';  // 数字资源列表 GET

// 数字资源 --- 智能教辅 导航栏接口
export const getAllConfStage = '/api/admin/stage/getAllConfStage';  // 导航栏 阶段 GET

export const getSubjects = '/api/admin/confSubject/getSubjects';  // 导航栏 学科 GET

export const getVolumesByEditionName = '/api/admin/confVolumeEntity/getVolumesByEditionName';  // 导航栏 册次 GET

export const getEditionName = '/api/admin/confWebsite/getEditionName';  // 导航栏 版本 GET

// 解透教材
export const getAnalyzeBook = prefix + 'webSite/tryLearn/getAnalyzeBook'; //获取解透教材章节资源信息

export const getReferenceCatalogAndColumnCourse = prefix + 'webSite/tryLearn/getReferenceCatalogAndColumnCourse'; //章节关联栏目下资源

export const getFile = prefix + 'webSite/tryLearn/getReferenceFile'; //获取某个具体资源

export const addUsedNum = prefix + 'resource/addUsedNum'; //强化训练页面查看打印次数计数

export const getReferenceBookFileId = prefix + 'webSite/tryLearn/getReferenceBookFileId'; //电子图片表查询(返回对应页图片id，为空证明到最后一页)


export const getAnalyzeBook_ = prefix + 'book/getAnalyzeBook'; //获取解透教材章节资源信息  --学生端
// export const getAnalyzeBook_ = prefix + 'book/getTemporaryAnalyzeBook'; //获取解透教材章节资源信息  --学生端

export const getReferenceCatalogAndColumnCourse_ = prefix + 'resource/getReferenceCatalogAndColumnCourse'; //章节关联栏目下资源  --学生端
// export const getReferenceCatalogAndColumnCourse_ = prefix + 'resource/getNewReferenceCatalogAndColumnCourse'; //章节关联栏目下资源  --学生端

export const getFile_ = prefix + 'resource/getReferenceFile'; //获取某个具体资源 --学生端

export const addUsedNum_ = prefix + 'resource/addUsedNum'; //强化训练页面查看打印次数计数 --学生端
 
export const getReferenceBookFileId_ = prefix + 'book/getReferenceBookFileId'; //电子图片表查询(返回对应页图片id，为空证明到最后一页) --学生端

// 视频互动做题
export const getResourceInteractDetail = prefix + 'resourceInteractRecord/getResourceInteractDetail'; // 根据资源id与出现的时刻获得试题信息详情

export const saveResourceInteractRecord = prefix + 'resourceInteractRecord/saveResourceInteractRecord'; // 保存互动答题记录

export const isTextBook = prefix + 'webSite/tryLearn/isTextBook'; // 是否展示教材


// 登录

export const forgetPassword = userPrefix + 'forgetPassword'; //忘记密码验证手机号或者邮箱,发送验证码

export const checkCode = userPrefix + 'checkCode';

export const bindOrNot = userPrefix+'bindOrNot'; //判断邮箱或手机号是否绑定

export const verification = userPrefix + 'verification'; //验证码验证或(绑定邮箱或绑定者手机号)-修改邮箱或者密码

export const updatePassword = userPrefix + 'updatePassword'; //根据邮箱和手机修改密码

export const getOneCode = userPrefix + 'getOneCode'; //用户验证码登录

export const userUseRecordSave = prefix + 'userUseRecord/save'; // 保存用户使用app记录

export const login = userPrefix + 'login'; //用户登录接口

export const shortMessageLogin = userPrefix + 'shortMessageLogin'; //用户验证码登录

export const logout = userPrefix + 'logout'; //登出

export const getAccount = userPrefix + 'getAccount'; //个人中心获取账户信息

export const validateToken = prefix + 'webSite/tryLearn/validateToken'; //验证token有无过期



