export const imgUrl = (url) => `/public_static/websitePc/${url}?123`;

export const oldImgUrl = (url) => `/public_static/teachingassistant/${url}`; // 旧接口

export const imgUrlForTeacher = (url) => `/public_static/teachingassistant/teacher/${url}`;

export const newStudentPc = (url) => `/public_static/newStudentPc/${url}`;

// .zjyjc.com
// export const domain_ = () => 'localhost';
export const domain_ = () => window.location.origin.indexOf("localhost") !== -1 ? 'localhost' : '.zjyjc.com';

// export const href_ = () => 'http://localhost:3001/';
export const href_ = () => {
  let ip = window.location.origin;
  
  if (ip.indexOf("localhost") !== -1) {
    return ip.indexOf('3000') !== -1 ? 'http://localhost:3001' : 'http://localhost:3000';
  } 
  
  else if(ip.indexOf('dev') !== -1) {
    return 'http://dev-rj.zjyjc.com';
  }

  else if(ip.indexOf('test') !== -1) {
    const testSrc = ip.indexOf('test') !== -1 ? ip?.split('-')[0] : 'test'
    return `${testSrc}-rj.zjyjc.com`;
  }

  else if(ip.indexOf('pre-www.zjyjc.com') !== -1) {
    return "http://pre-rj.zjyjc.com";
  }

  else {
    return 'http://student.zjyjc.com'
  }
}

export const getIp = () => window.location.origin;

export function showImg(id = '', initialId = '') {
  if (id) return `/api/media/api/v1/media/showImage/${id}?123`;

  if (initialId) return `/api/media/api/v1/media/showImage/${initialId}?123`;

  return '';
}

// 获取地址栏参数
export const getParam = function () {
  let arr = window.location.href.split('?');
  if (arr.length <= 1) return {};
  let qs = arr[1],
    args = {},
    items = qs.length ? qs.split("&") : [],
    item = null,
    name = null,
    value = null,
    i = 0,
    len = items.length;
  for (i = 0; i < len; i++) {
    item = items[i].split("=");
    name = decodeURIComponent(item[0]);
    value = decodeURIComponent(item[1]);
    if (name.length) {
      args[name] = value;
    }
  }
  return args || {};
};

//试题类型
// {"1":"选择题", "2": "填空题", "3": "多选题", "4": "判断题", "8":"综合题", "9":"解答题"}
export const QuestionTestForm = new Map([
  ["1", "选择题"],["2", "填空题"],["3", "多选题"],["4", "判断题"],["8", "综合题"],["9", "解答题"],["11", "选择题"],["13", "多选题"],["12", "判断题"],["14", "综合题"]
]);

export const ZiPingData = [
    {key:'self:A', text:"️正确，bingo", icon:"/public_static/teachingassistant/aiFrameLiu.png"},
    {key:'self:B', text:"️只对了一半哦 ～", icon:"/public_static/teachingassistant/aiFrameCLiu.png"},
    {key:'self:C', text:"️错误，继续努力", icon:"/public_static/teachingassistant/aiFrameRLiu.png"},
];

const cutData = option => {
  if(!option) return;
  let preview = '';

  if(option.includes('dev')) {
      preview = 'dev-rj.zjyjc.com'
  } else if (option.includes('test') || option.includes('localhost')) {
      preview = 'test-rj.zjyjc.com'
  } else if (option.includes('pre')) {
      preview = 'pre-rj.zjyjc.com'
  } else {
      preview = 'student.zjyjc.com'
  }
  return preview
}

export const getIps = (preview_) => {
  let preview = preview_ || window.location.origin || '';
  if(!preview) return '';
  const str_ = cutData(preview);

  if(preview.indexOf('https') !== -1) {
      const str = preview.slice(0,5);
      preview = `${str}://${str_}`;
  } else {
      const str = preview.slice(0,4);
      preview = `${str}://${str_}`;
  }
  return preview;
}

export const defaultImg = (item) => {
  // '视频': 1,  '音频': 2, '动画': 3, '文本': 4, '图片': 5, '其他': 6, '网页链接': 7         coverFileId字段 文本(pdf:1, ppt: 2, word: 3)  

  switch (item.resourceFormat) {
    case '1':
      return imgUrl('video.png');
    case '2':
      return imgUrl('audio.png');
    case '3':
      return imgUrl('video.png');
    case '4':
      if (item.coverFileId === '1') {
        return imgUrl('pdf.png');
      } else if (item.coverFileId === '2') {
        return imgUrl('ppt.png');
      } else {
        return imgUrl('doc.png');
      }
    case '5':
      return imgUrl('img.png');
    case '7':
      return imgUrl('html5.png');
    default:
      return '';
  }
}

export const modalWidth = (resourceFormat, videoWidth) => {
  // '视频': 1,// '音频': 2,// '动画': 3,// '文本': 4,// '图片': 5, // '其他': 6,// '网页链接': 7,
  switch (resourceFormat) {
    case '1':
      return videoWidth;
    case '2':
      return 800;
    case '3':
      return 1000;
    case '4':
      return videoWidth;
    case '5':
      return videoWidth;
    case '6':
      return 1000;
    case '7':
      return 1000;
    default:
      return ;
  }
}

export const setTimesMin_ = function(value) {
  // let secondTime = parseInt(value);
  let secondTime = Math.round(value);
  let min = 0;
  // let h = 0;
  let result = "";
  if (secondTime > 60) {
    min = parseInt(secondTime / 60);
    secondTime = parseInt(secondTime % 60);
    // if (min > 60) {
    //   h = parseInt(min / 60);
    //   min = parseInt(min % 60);
    // }
  }
  result = `${min
    .toString()
    .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
  return result;
};

export const exludesBookIds = (bookId) => {
  //04efb7fc673ff3f83825579ea04780e9,3caae1f0e92de3d0495d1a1dbec22934,c516cb2b999eb06445c2952b6ac2000c,e41af5e3fefb4470f1da28bb201586c0,2f7c17dd3ec0cc48d93caa8dee626711,4e386b36219529c719592f22240d3b42,b765421d2bcfffc3d4f07e1a22835e51,fffc659b4ee4df5c3a0ebe250c19a30b,1517227c46d94c0461865a5241bfade8,1f693ca0f7ac39cfa4abe76afe55ad63,94223af8fed4b27fa0acdd063a1e202f,e124127540891ed3a1ec2355dee46b78
  let str = "3caae1f0e92de3d0495d1a1dbec22934,04efb7fc673ff3f83825579ea04780e9,2f7c17dd3ec0cc48d93caa8dee626711,4e386b36219529c719592f22240d3b42,b765421d2bcfffc3d4f07e1a22835e51,fffc659b4ee4df5c3a0ebe250c19a30b"
  str = ""; // 没有限制的书籍了
  const ids = str.split(',');
  return ids.indexOf(bookId) >= 0
  }