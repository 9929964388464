import * as TYPES from '@/constants/actionTypes';

const defaultState = {
  newsDetail: {},
  resourceList: [],
  referenceData: {},
  referenceList: [],
  navJumpData: {
    id: '', // 请求接口需要的字段
    jumpFlag: '',  // 判断跳转区域
    text: ''
  },
  videoStatus: true
}

export default function home(state = defaultState, action) {
  switch (action.type) {
    case TYPES.HOME_SAVE_NEWS_DATA:
      return {
        ...state,
        newsDetail: action.payload
      }
    case TYPES.HOME_SAVE_RESOURCE:
      return {
        ...state,
        resourceList: action.payload
      }
    case TYPES.HOME_SAVE_REFEFERENCE:
      return {
        ...state,
        referenceData: action.payload
      }
    case TYPES.HOME_SAVE_REFEFERENCELIST:
      return {
        ...state,
        referenceList: action.payload
      }
    case TYPES.SET_NAV_JUMP_STATUS:
      return {
        ...state,
        navJumpData: action.payload
      }
    case TYPES.SET_VIDEO_STATUS:
      return {
        ...state,
        videoStatus: action.payload
      }

    default:
      return state
  }
}