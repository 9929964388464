import React, { Component } from 'react';
import axios from 'axios';
import { get, post } from "@/utils/request";
import { connect } from 'react-redux';
import { clearToken, updateUser, updateToken, insertMedia } from '@/actions/login';
import { domain_, getIp } from "@/utils";
import Cookies from 'js-cookie';
import { getAccount, validateToken } from '@/api';
import { withRouter } from 'react-router-dom';

export default @withRouter
@connect((state) => {
  return {
    user_: state.login.user,
    token: state.login.token,
  }
}, {
  clearToken,
  updateUser,
  updateToken,
  insertMedia
})

class extends Component {
  constructor (props) {
    super(props);

    let cancelToken = axios.CancelToken;
    const cancel = []
    const removePending = config => {
      for(let p in cancel){
        if (cancel[p].u === config.url) {
          cancel[p].f()
        }
      }
    }
    
    axios.interceptors.request.use(config => {
      config.cancelToken = new cancelToken(c => {
        cancel.push({ 
          f: c,
          u: config.url,
        })
      })
      return config
    }, error => {
      return Promise.reject(error)
    })

    // 添加响应拦截器
    axios.interceptors.response.use(response => {
      const { user = {}, types = '', mediaId = '', bindTel } = Cookies.getJSON();

      // 学生端进行了账户退出
      if (Object.keys(user).length === 0 && types === 'student') {
        // 清除掉 cookie 里的types
        Cookies.remove('types', { path: '/', domain: domain_() });
        props.clearToken(null);
        props.updateUser({});
        return
      } 

      // 进行了修改头像
      if (mediaId) {
        props.insertMedia(mediaId);
        // 清除掉 cookie 里的mediaId. 
        Cookies.remove('mediaId', { path: '/', domain: domain_() });
        return
      }

      // 学生端进行了登录
      if (types === 'student' && Object.keys(user).length !== 0) {
        // 清除掉 cookie 里的types. 
        Cookies.remove('types', { path: '/', domain: domain_() });
        // if(!user?.id) return;
        // 设置token
        props.updateToken(user?.token);
        get(getAccount, { id: user?.id }).then(res => {
          if(res.code === 0){
            const res_ = {
              ...res?.result,
              roleTypeId: res?.roleType,
              token: user?.token
            }
            // 保存值
            props.updateUser(res_ || {});
          }     
        })
        return
      }

      // 进行了绑定手机号码
      if(bindTel) {
        const res = {
          ...props.user_,
          telNum: bindTel
        }
        // 保存值
        props.updateUser(res || {});

        // 清除掉 cookie bindTel. 
        Cookies.remove('bindTel', { path: '/', domain: domain_() });
        return;
      }

      return response;
    }, error => {

      switch (error.code) {
        case 401:
          // window.location.href = 'https://www.baidu.com'
          break;
        
        default:
          break;
      }
      return Promise.reject(error);
    });
  }

  componentDidMount () {

    setInterval(async () => {
      if(!this.props?.token) return;

      const res = await post(validateToken, { token: this.props?.token || '' });

      // if(res?.code === 401 || res?.code === 4011){
      if(res?.code === 401){
        const { clearToken, updateUser } = this.props;
        clearToken(null);
        Cookies.remove('user');
        updateUser({});
        // if(res?.code === 4011) {
        //   message.warning(res.message)
        // }
      }
    }, 10000);
  }

  render () {
    return <></>
  }
}

