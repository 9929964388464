import loadable from '@/utils/loadable.js'

const Layout = loadable( () => import('@/layout') );
const Home = loadable( () => import('@/pages/home') );  //首页
const News = loadable( () => import('@/pages/news') );  //新闻资讯
const Reference = loadable( () => import('@/pages/reference') );  //智能教辅
const Resource = loadable( () => import('@/pages/resource') );  //数字资源
const Product = loadable( () => import('@/pages/product') );  //产品中心
const BigData = loadable( () => import('@/pages/bigData') );  //大数据中心
const Collaborate = loadable( () => import('@/pages/collaborate') );  //开放合作

const News_dateil = loadable( () => import('@/pages/news_detail') );  // 新闻资讯 详情
const ReferenceDetail = loadable( () => import('@/pages/reference_detail') );  //  智能教辅 详情
const DialysisMetaro = loadable( () => import('@/pages/DialysisMetaro/DialysisMetaro') );  //  智能教辅 解透教材详情
const AndroidInterim = loadable( () => import('@/pages/androidInterim') );  // 安卓二维码过渡页 // 把这个路由生成一个过渡页二维码。
const NewAndroidInterim = loadable( () => import('@/pages/NewAndroidInterim') );  // 新安卓二维码过渡页 // 把这个路由生成一个过渡页二维码。
const PhoneInterimC = loadable( () => import('@/pages/phoneInterimC') );  // 移动端C端二维码过渡页 // 把这个路由生成一个过渡页二维码。
const PhoneInterimB = loadable( () => import('@/pages/phoneInterimB') );  // 移动端B端二维码过渡页 // 把这个路由生成一个过渡页二维码。


const Login = loadable( () => import('@/pages/Login/Login') );  // 登录
const Agreement = loadable( () => import('@/pages/Agreement') );  
const Reference_ = loadable( () => import('@/pages/reference_') );  //智能教辅

const PhoneInterimNew = loadable( () => import('@/pages/phoneInterimNew') );  // 移动端C端 new过渡页
const phoneInterimSingle = loadable( () => import('@/pages/phoneInterimSingle') );  // 移动端C端 new过渡页

const Demo = loadable( () => import('@/pages/Demo') );  // 

export {
  Home,
  Layout,
  News,
  Reference,
  Resource,
  Product,
  BigData,
  Collaborate,
  News_dateil,
  ReferenceDetail,
  DialysisMetaro,
  AndroidInterim,
  NewAndroidInterim,
  PhoneInterimC,
  PhoneInterimB,
  Login,
  Agreement,
  Reference_,
  PhoneInterimNew,
  phoneInterimSingle,
  Demo,
}