/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2022-05-09 13:55:14
 * @LastEditors: nliu
 * @LastEditTime: 2022-09-13 10:57:51
 */
import {
  Home,
  Layout,
  News,
  Reference,
  Resource,
  Product,
  BigData,
  Collaborate,
  News_dateil,
  ReferenceDetail,
  DialysisMetaro,
  AndroidInterim,
  NewAndroidInterim,
  PhoneInterimC,
  PhoneInterimB,
  Login,
  Agreement,
  Reference_,
  PhoneInterimNew,
  phoneInterimSingle,
  Demo,
} from './assembly';

const routes = [
  {
    path: "/reference_", 
    component: Reference_,
  },
  {
    path: "/agreement", 
    component: Agreement,
  },
  {
    path: "/login", 
    component: Login,
  },
  {
    path: "/phone_interim", 
    component: PhoneInterimC,
  },
  {
    path: "/phone_interimB", 
    component: PhoneInterimB,
  },
  {
    path: "/android_interim", 
    component: AndroidInterim,
  },
  {
    path: "/android_interim_new",  // 弃用
    component: NewAndroidInterim,
  },
  {
    path: "/reference_detail",
    component: ReferenceDetail,
  },
  {
    path: "/dialysisMetaro",
    component: DialysisMetaro,
  },
  {
    path: "/phone_interim_chinese-3-1",
    component: PhoneInterimNew,
  },
  {
    path: "/phone_interim_single",
    component: phoneInterimSingle,
  },
  {
    path: "/demo",
    component: Demo,
  },
  {
    path: "/",
    component: Layout,
    children: [
      { path: "/news", component: News },
      { path: "/news_dateil", component: News_dateil },
      { path: "/reference", component: Reference },
      { path: "/resource", component: Resource },
      { path: "/product", component: Product },
      { path: "/bigdata", component: BigData },
      { path: "/collaborate", component: Collaborate },
      { path: "/", component: Home },
    ]
  },
]

export default routes;