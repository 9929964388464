import * as TYPES from '@/constants/actionTypes';

const defaultState = {
  token: null,
  user: {},
  mediaId: ''
}

export default function home(state = defaultState, action) {
  switch (action.type) {
    case TYPES.UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case TYPES.CLEAR_TOKEN:
      return {
        ...state,
        token: '',
        user: {},
        mediaId: ''
      };
    case TYPES.UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case TYPES.INSERT_MEDIAID:
      return {
        ...state,
        mediaId: action.payload,
      };

    default:
      return state
  }
}